<script>
import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import { useApiStore } from "@/pinia/api";

export default {
  props: {
    displayMap: String,
    sapParam: String,
  },

  data() {
    return {
      api: useApiStore(),
      concessadress: "",
      map: null,
    };
  },

  mounted() {
    this.loadMap();
  },

  methods: {
    isMobileDevice() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
      );
    },

    loadMap() {
      if (this.sapParam) {
        // Configuration de la requête API pour récupérer les concessions
        const myHeaders = new Headers();
        myHeaders.append("xc-api-token", this.api.xcToken);
        myHeaders.append("x-table-id", this.api.Dealer);
        const requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };

        // Récupération des données depuis l'API
        fetch(
            this.api.baseUrl + "?where=(sap,eq," + this.sapParam + "%29",
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
              if (result.list[0].sap === this.sapParam) {
                this.concessadress =
                    result.list[0].streetAndNumber + " - " + result.list[0].city;

                // Détection si l'appareil est mobile pour désactiver l'interactivité
                const isMobile = this.isMobileDevice();

                // Initialisation de la carte
                this.map = new maplibregl.Map({
                  container: 'mapContainer',
                  style: require('@/assets/map/style.json'),
                  center: [parseFloat(result.list[0].lng), parseFloat(result.list[0].lat)],
                  zoom: 15,
                  interactive: !isMobile,
                });

                // Ajout d'un marqueur à la position de la concession
                const el = document.createElement("div");
                el.className = "markermap";
                new maplibregl.Marker({ element: el })
                    .setLngLat([parseFloat(result.list[0].lng), parseFloat(result.list[0].lat)])
                    .addTo(this.map);

                // Animation et chargement de la carte
                this.map.on("load", () => {
                  document
                      .querySelector(".map_loading")
                      .classList.add("map_loaded");
                });
              } else {
                this.$emit("sapFalse"); // Aucune correspondance trouvée
              }
            })
            .catch((error) => console.log("error", error));
      } else {
        // Pas de paramètre SAP, récupération de la liste complète des concessions
        const myHeaders = new Headers();
        myHeaders.append("xc-api-token", this.api.xcToken);
        myHeaders.append("x-table-id", this.api.Dealer);
        const requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };

        fetch(this.api.baseUrl + "?sort=zip&limit=250&fields=zip,name,sap,lat,lng", requestOptions)
            .then((response) => response.json())
            .then((result) => {
              this.select_concess = result.list;
            })
            .catch((error) => console.log("error", error));
      }
    },
  },
};
</script>

<template>
  <div class="map">
    <div class="row__map">
      <div class="mapheader">
        <span>{{ concessadress }}</span>
      </div>
      <div id="mapContainer" style="height: 250px;"></div>
      <div class="map_loading">Chargement de la carte...</div>
    </div>
  </div>
</template>

<style scoped>
</style>
