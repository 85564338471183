<template>
  <div class="form" id="map">
    <img
      :src="data.Maindata.formulaire[0].trackingForm"
      alt="tracking"
      class="tracking"
      v-if="image"
    />
    <form
      @submit="SubmitForm"
      class="form"
      method="POST"
      action="send/FormMap.php"
      id="formulaire"
      autocomplete="off"
    >
      <div class="title">
        <picture>
          <source :srcset="darklogo" media="(prefers-color-scheme:dark)" />
          <img
            class="home__formsection__logo"
            :src="logo"
            alt="Hyundai"
            width="auto"
            height="auto"
          />
        </picture>

        <h2>{{ data.Maindata.formulaire[0].titleForm }}</h2>
        <h3 v-if="!error">{{ data.Maindata.formulaire[0].subtitleForm }}</h3>
        <h3 v-if="concessname">{{ concessname }}</h3>
        <p>{{ concessadress }}</p>
      </div>
      <div class="row" v-if="selectVehicleOption">
        <fieldset class="offre">
          <select
            :class="{ selected: input_selectmodel !== '' }"
            id="js_input_selectmodel"
            v-model="input_selectmodel"
            class="formlp"
            name="car"
          >
            <option value="" disabled selected>Sélectionner un véhicule</option>
            <option
              v-for="(item, index) in selectoption"
              :key="index"
              :value="item.list[0].slug"
              class="option"
              name="car"
            >
              {{ item.list[0].title }}
            </option>
          </select>

          <div
            :class="{ active: input_selectmodel !== '' }"
            class="select__offre"
            v-if="!data.Maindata.formulaire[0].selectVehicle"
          >
            <div class="select__offre__transi"></div>
            <div class="select__offre__right">
              <img
                v-if="select_clearcutSrc"
                :src="select_clearcutSrc"
                alt="hyundai"
                width="auto"
                height="auto"
              />
            </div>
            <div class="select__offre__left">
              <img
                v-if="select_offreSrc"
                :src="select_offreSrc"
                alt="offre"
                width="auto"
                height="auto"
              />
              <span @click="DisplayML">Voir conditions</span>
            </div>
          </div>
        </fieldset>
      </div>

      <div class="row">
        <fieldset>
          <select
            :class="{ selected: input_selectgender !== '' }"
            id="js_input_selectgender"
            name="civilite"
            v-model="input_selectgender"
            class="formlp"
          >
            <option selected disabled value="">Civilité</option>
            <option value="Mme">Madame</option>
            <option value="Mr">Monsieur</option>
          </select>
        </fieldset>
      </div>

      <div class="row">
        <fieldset>
          <label
            class="labelAnim"
            :class="{ activeLabel: input_firstname !== '' }"
            for="js_input_firstname"
            name="firstname"
            >Prénom</label
          >
          <input
            class="formlp vueverif"
            data-regex="^[a-zA-Z-' ]+$"
            v-model="input_firstname"
            name="firstname"
            id="js_input_firstname"
          />
        </fieldset>

        <fieldset>
          <label
            class="labelAnim"
            :class="{ activeLabel: input_lastname !== '' }"
            for="js_input_lastname"
            name="lastname"
            >Nom</label
          >
          <input
            class="formlp vueverif"
            data-regex="^[a-zA-Z-' ]+$"
            v-model="input_lastname"
            name="lastname"
            id="js_input_lastname"
          />
        </fieldset>
      </div>

      <div class="row">
        <fieldset>
          <label
            class="labelAnim"
            :class="{ activeLabel: input_email !== '' }"
            for="js_input_email"
            name="email"
            >Email</label
          >
          <input
            class="formlp vueverif"
            data-regex="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
            v-model="input_email"
            id="js_input_email"
            name="email"
          />
        </fieldset>
        <fieldset>
          <label
            class="labelAnim"
            :class="{ activeLabel: input_tel !== '' }"
            for="js_input_tel"
            name="tel"
            >N° de téléphone</label
          >
          <input
            class="formlp vueverif"
            data-regex="^(01|02|03|04|05|06|07|09) \d{2} \d{2} \d{2} \d{2}$"
            v-mask="'## ## ## ## ##'"
            v-model="input_tel"
            id="js_input_tel"
            name="tel"
            autocomplete="one-time-code"
          />
        </fieldset>
      </div>

      <div class="row" v-if="!displayMap">
        <fieldset>
          <label
            class="labelAnim"
            :class="{ activeLabel: input_zipcode !== '' }"
            for="js_input_zipcode"
            name="zipcode"
            >Code postal</label
          >
          <input
            class="formlp vueverif"
            data-regex="^\d{5}$"
            v-mask="'#####'"
            v-model="input_zipcode"
            id="js_input_zipcode"
            name="zipcode"
          />
        </fieldset>
        <fieldset v-if="!errorAPI">
          <select
            :class="{ selected: input_selectcity !== '' }"
            id="js_input_selectcity"
            name="selectcity"
            v-model="input_selectcity"
            class="formlp"
          >
            <option selected disabled value="">Ville</option>
            <option
              v-for="(item, index) in select_city"
              :key="index"
              :value="item.nom_de_la_commune"
            >
              {{ item.nom_de_la_commune }}
            </option>
          </select>
        </fieldset>

        <fieldset v-else>
          <label
            class="labelAnim"
            :class="{ activeLabel: input_selectcity !== '' }"
            for="js_input_selectcity"
            name="selectcity"
            >Ville</label
          >
          <input
            class="formlp vueverif"
            v-model="input_selectcity"
            id="js_input_selectcity"
            name="selectcity"
          />
        </fieldset>
      </div>

      <Map v-if="displayMap" :sapParam="sapParam" @sapFalse="sapFalse" />

      <div v-else class="row">
        <fieldset class="offre">
          <select
            :class="{ selected: input_selectconcess !== '' }"
            id="js_input_selectconcess"
            v-model="input_selectconcess"
            class="formlp"
            name="concess"
          >
            <option value="" disabled selected>
              Sélectionner un distributeur
            </option>
            <option
              v-for="(item, index) in select_concess"
              :key="index"
              :value="item.sap"
              v-if="item.zip"
            >
              {{ item.zip.substring(0, 2) }} - {{ item.name }}
            </option>
            <option
              v-for="(item, index) in select_concess"
              :key="index"
              :value="item.sap"
              v-if="!item.zip"
            >
              {{ item.name }}
            </option>
          </select>
        </fieldset>
      </div>

      <div class="row" v-if="optin === true">
        <p class="title_optin">Personnalisation de votre expérience Hyundai</p>
      </div>

      <div class="row">
        <fieldset v-if="optin === true">
          <label class="customcheckbox">
            Je souhaite recevoir des communications personnalisées et des
            publicités ciblées basées sur mes préférences et mon utilisation des
            produits et services de Hyundai Motor France et de son réseau de
            distributeurs et réparateurs agréés. Pour cela, Hyundai Motor France
            créera mon profil client individuel.
            <details>
              <summary class="details">En savoir plus</summary>
              En cochant cette case, vous acceptez que Hyundai Motor France vous
              adresse des communications personnalisées par courrier
              électronique, téléphone, courrier et/ou services de messagerie
              ainsi que des publicités ciblées sur internet et sur les réseaux
              sociaux.
              <br /><br />
              Pour cela Hyundai Motor France créera un profil client individuel
              basé sur vos préférences et intérêts combinant des données sur
              votre utilisation de nos produits et services et de nos sites et
              applications, des données sur la façon dont vous interagissez avec
              nos campagnes marketing, des données socio-économiques ainsi que
              des données relatives à l’utilisation de votre véhicule.
              <br /><br />
              Pour vous adresser des publicités ciblées sur internet sur les
              réseaux sociaux, nous serons amenés à transférer vos données
              personnelles à des sociétés tierces.
            </details>

            <input
              type="checkbox"
              id="js_input_news"
              v-model="input_news"
              name="optin"
              class="formlp"
            />
            <span class="customcheckbox__checkmark"></span>
          </label>
        </fieldset>
      </div>
      <div class="row">
        <div class="col-xs-12">
          <p class="title_optin">
            Je comprends que je peux modifier ou retirer mon consentement au
            traitement de mes données personnelles à tout moment.
          </p>

          <details>
            <summary class="details">En savoir plus</summary>
            Vos préférences
            <br /><br />
            Vous pouvez modifier en ligne le degré de présence de Hyundai que
            vous souhaitez dans votre vie. Pour modifier ou retirer votre
            consentement,
            <a
              href="https://hyundai-europe-privacy.my.onetrust.com/ui/#/preferences/multipage/login/9e77c974-41a8-427d-b896-0399e53f4efb"
              target="_blank"
              >cliquez ici</a
            >
            ou cliquez sur le lien de désabonnement dans chaque communication
            que nous vous envoyons.
            <br />
            <br />
            Remarque : le retrait ou la modification de votre consentement
            n'affecte pas la légalité du traitement fondé sur le consentement
            avant son retrait ou sa modification.
          </details>
          <br />
          Pour plus d'informations sur le traitement de mes données personnelles
          et de mes droits, je peux me référer à la
          <a
            class="personnal-data"
            href="https://www.hyundai.com/fr/fr/rgpd.html"
            target="_blank"
            >politique traitement de mes données personnelles</a
          >
        </div>
      </div>

      <div class="row">
        <div class="row">
          <button v-if="!postformValid" class="sendForm">
            <span>
              <span>{{
                this.error === true
                  ? "Confirmer"
                  : data.Maindata.formulaire[0].ctaForm
              }}</span>
            </span>
          </button>
          <button v-if="postformValid" class="sendForm">
            <span>
              <span>{{
                this.error === true
                  ? "Confirmer"
                  : data.Maindata.formulaire[0].ctaForm
              }}</span>
            </span>
          </button>
        </div>
      </div>

      <input
        type="hidden"
        :value="data.Maindata.campaign"
        name="campaign"
        class="formlp"
      />
      <input
        type="hidden"
        :value="data.Maindata.formulaire[0].TypeValue"
        name="type"
      />

      <input
        type="hidden"
        :value="utmParteners"
        name="parteners"
        class="formlp"
      />
      <input type="hidden" :value="utmSource" name="source" class="formlp" />
      <input type="hidden" :value="randuniq" name="randuniq" />
    </form>
  </div>
</template>

<script>
import Vue from "vue";
import VueMask from "v-mask";
Vue.use(VueMask);

//JS
import FormValidation from "@/assets/js/FormValidation";
import Map from "../features/MapView.vue";
import stores from "@/assets/js/store/store";
import { ToggleML, ToggleRGPD } from "@/assets/js/ToggleML";
import {
  input_zipcodeConcess,
  reordonnerParDistance,
} from "@/assets/js/store/concessDistance";
import { modeleVehicle } from "@/assets/js/store/modeleVehicle";
import Manage from "@/assets/js/ManageForm";
import { useApiStore } from "@/pinia/api";

import logo from "@/assets/img/Hyundai_Logo.svg";
import darklogo from "@/assets/img/Hyundai_Logo_dark.svg";

export default {
  name: "FormMap",

  props: {
    data: Object,
    scenario: String,
    utmSource: String,
    utmParteners: String,
    sapParam: String,
    modele: String,
    postformValid: Boolean,
    error: Boolean,
    optin: Boolean,
    randuniq: String,
    formParams: Object,
  },

  components: {
    Map,
  },

  data() {
    return {
      api: useApiStore(),
      mentions: null,
      logo: logo,
      darklogo: darklogo,
      selectVehicleOption: true,
      //INPUT FORMUALIRE
      input_selectmodel: "",
      input_email: "",
      input_tel: "",
      input_selectgender: "",
      input_firstname: "",
      input_lastname: "",
      input_zipcode: "",
      input_selectcity: "",
      input_news: "",
      input_news2: "",
      input_selectconcess: "",
      ArrayInput: [],
      ArraySelectModele: [],

      //AUTRE
      valideform: true,
      selectoption: [],
      select_clearcutSrc: "",
      select_offreSrc: "",
      select_concess: "",
      select_city: [],
      errorAPI: false,
      optionsCiv: ["Mr", "Mme"],
      value: "",
      postform: false,

      //MAP
      displayMap: true,
      concessname: "",
      concessadress: "",
      sap: true,
      image: false,
    };
  },

  watch: {
    //WATCH LE CHAMPS MODEL
    async input_selectmodel(newValue, oldValue) {
      const selectedModel = this.selectoption.find(
        (model) => model.list[0].slug === newValue
      );
      this.mentions = selectedModel.list[0].slug;
      if (!this.data.Maindata.formulaire[0].selectVehicle) {
        this.ArraySelectModele = await modeleVehicle(
          selectedModel.list[0].slug,
          this.selectoption,
          this.api.baseUrl,
          stores.bloclist
        );
        this.select_clearcutSrc = this.ArraySelectModele[0];
        this.select_offreSrc = this.ArraySelectModele[1];
      }
    },

    input_selectcity(newValue) {
      if (newValue !== "") {
        const selectedCity = this.select_city.find(
          (city) => city.nom_de_la_commune === newValue
        );
        if (selectedCity) {
          const lat = selectedCity.coordonnees_geographiques[0].lat;
          const lng = selectedCity.coordonnees_geographiques[0].lon;
          reordonnerParDistance(this.select_concess, lat, lng);
          this.input_selectconcess = this.select_concess[0].sap;
        }
      }
    },

    //WATCH LE CHAMPS CODE POSTAL
    async input_zipcode(newValue, oldValue) {
      this.ArrayInput = await input_zipcodeConcess(
        newValue,
        this.select_concess
      );
      this.input_selectconcess = this.ArrayInput[0];
      this.select_city = this.ArrayInput[1];
      this.input_selectcity = this.ArrayInput[2];
      this.errorAPI = this.ArrayInput[3];
      if (this.select_city.length >= 1) {
        this.select_city.forEach((city) => {
          if (city.nom_de_la_commune === this.formParams.city) {
            this.input_selectcity = city.nom_de_la_commune;
          }
        });
      }
    },
  },

  mounted() {
    this.loadMap();
    this.FetchData();
  },

  methods: {
    initParams() {
      if (this.formParams.gender === "Mme" || this.formParams.gender === "Mr") {
        this.input_selectgender = this.formParams.gender;
      }
      console.log(this.formParams);
      if (this.formParams.firstname !== undefined)
        this.input_firstname = this.formParams.firstname;
      if (this.formParams.lastname !== undefined)
        this.input_lastname = this.formParams.lastname;
      if (this.formParams.email !== undefined)
        this.input_email = this.formParams.email;
      if (this.formParams.phone !== undefined)
        this.input_tel = this.formParams.phone;
      if (this.formParams.cp !== undefined)
        this.input_zipcode = this.formParams.cp;
    },

    //LIS DE PARAMETRE MODEL DE L'URL POUR AFFICHER LE BON MODELE DANS LE SELECT
    InitSelect() {
      const model = Manage.ManageSelectModel();

      for (let i = 0; i < this.selectoption.length; i++) {
        if (this.selectoption[i].list[0].slug === model) {
          this.select_clearcutSrc =
            this.api.baseUrl +
            "/i?" +
            this.selectoption[i].list[0].images[0].path;
          this.mentions = this.selectoption[i].list[0].slug;
          this.input_selectmodel = this.selectoption[i].list[0].slug;
          stores.slug_mention = this.selectoption[i].list[0].slug;
        }
      }
    },

    postForm() {
      let postformView = document.getElementById("postform");

      if (postformView) {
        postformView.classList.add("active");
      }
    },

    sapFalse() {
      this.displayMap = false;
      this.selectConcessMapNone();
    },

    loadMap() {
      //PARAMETRAGE CODE SAP
      console.log(this.sapParam);
      if (this.sapParam) {
        this.displayMap = true;
      }
      //SI PAS DE CODE SAP EN URL
      else {
        this.displayMap = false;
        this.selectConcessMapNone();
      }
    },

    selectConcessMapNone() {
      //FETCH DE LA LISTE DE TOUTES LE CONCESSIONS
      var myHeaders = new Headers();
      myHeaders.append("xc-api-token", this.api.xcToken);
      myHeaders.append("x-table-id", this.api.Dealer);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      fetch(
        this.api.baseUrl + "?sort=zip&limit=250&fields=zip,name,sap,lat,lng",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          this.select_concess = result.list;
          this.initParams();
        })
        .catch((error) => console.log("error", error));
    },

    DisplayML() {
      ToggleML();

      stores.slug_mention = this.mentions;
    },

    DisplayRGPD() {
      ToggleRGPD();
    },

    FetchData() {
      const modelelist = this.data.Maindata.formulaire[0].modeleForm;
      if (modelelist === undefined) {
        this.selectVehicleOption = false;
      } else {
        var myHeaders = new Headers();
        myHeaders.append("xc-api-token", this.api.xcToken);
        myHeaders.append("x-table-id", this.api.Modeles);
        var requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };

        //CREATION DES OPTIONS DU SELECT MODELE
        const fetchPromises = modelelist.map((item) =>
          typeof item === "object"
            ? fetch(
                `${this.api.baseUrl}?where=(slug,eq,${item[0]})`,
                requestOptions
              )
                .then((response) => response.json())
                .catch((error) => {
                  // Vous pouvez gérer les erreurs ici si nécessaire
                })
            : fetch(
                `${this.api.baseUrl}?where=(slug,eq,${item})`,
                requestOptions
              )
                .then((response) => response.json())
                .catch((error) => {
                  // Vous pouvez gérer les erreurs ici si nécessaire
                })
        );

        // Utilisez Promise.all pour attendre la fin de toutes les requêtes fetch
        Promise.all(fetchPromises)
          .then((results) => {
            results = results.filter((el) => el.list.length !== 0);

            // Toutes les requêtes fetch sont terminées ici, et les résultats sont dans le tableau "results"
            this.selectoption = results;
            if (this.selectoption.length < 1) {
              this.selectVehicleOption = false;
            }
            // console.log("Toutes les requêtes fetch sont terminées");

            // this.$nextTick est utilisé ici pour s'assurer que le DOM est mis à jour
            this.$nextTick(() => {
              let option = document.querySelectorAll(".option");
              // console.log(option);
              if (option.length === 1) {
                option[0].selected = true;
                this.input_selectmodel = option[0].value;
              }

              // Appelez votre fonction InitSelect ici si nécessaire
              this.InitSelect();
              this.selectModele();
            });
          })
          .catch((error) => {
            console.log("Erreur lors de l'attente des requêtes fetch", error);
          });
      }
    },

    selectModele() {
      // select modele if the parameter modele is in the url with number
      this.selectoption.forEach((item) => {
        console.log(item.list[0].slug);
        console.log(this.modele);
        if (item.list[0].slug === this.modele) {
          console.log(this.model);
          this.input_selectmodel = this.modele;
        }
      });
    },

    SubmitForm: function (e) {
      var form = document.getElementById("formulaire");
      e.preventDefault();
      const valid = FormValidation(this.scenario, "formlp");

      // Si le formulaire n'est pas valide, on arrête l'exécution de la fonction
      if (!valid) {
        console.warn("Le formulaire n'est pas valide.");
        return;
      } else {
        //Affichage du component de confirmation si le formulaire est valide
        if (!this.postformValid) {
          const confirmComponent = document.querySelector(".confirm");
          confirmComponent.classList.add("active");
        } else {
          this.postForm();
        }
      }

      // Création de l'objet FormData
      var formData = new FormData(form);

      //
      let dataObj = {};
      for (var pair of formData.entries()) {
        console.log(pair);
        dataObj[pair[0]] = pair[1];
      }
      // console.table(dataObj);

      fetch(form.action, { method: "post", body: formData }).then(
        (response) => {
          if (response.status === 200) {
            this.image = true;
          }
        }
      );

      if (CookieConsent.acceptedCategory("marketing")) {
        gtag("event", "conversion", {
          allow_custom_scripts: true,
          send_to: "DC-10321377/visit-hy/hyund00+standard",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/form.scss";
</style>
